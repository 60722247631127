import React from "react"
import { useShoppingCart } from 'use-shopping-cart'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import {
  Section,
} from "../styledComponents/section"
import HeroBanner from "./HeroBanner"
import { HeroText, HeroHeader, HeroDescription } from "../styledComponents/heroBanner"  
import { StyledAnchorLink, StyledRegisterNowButton, StyledCloseModalButton } from "../styledComponents/button"

const ShmProgramSales = () => {
    const { addItem, checkoutSingleItem } = useShoppingCart()

    return (
        <>
        <HeroBanner 
          image="/images/self-healing-mastery.jpeg" 
          backgroundPosition="0 0"
          largeBackgroundPosition="0 -260px" 
          largeBackgroundSize="100%"
          type="shm"
        >
          <HeroText>
            <HeroHeader isGift='true'>
            Still your mind and awaken your vitality
            </HeroHeader>
            <HeroDescription>Self Healing Mastery teaches you highly effective mindfulness meditation and self healing techniques to balance your mind and body.</HeroDescription>
            <StyledAnchorLink inverse="true" className="learn-more" to="/self-healing-mastery#learn">Learn more</StyledAnchorLink>
          </HeroText>
        </HeroBanner>
        <Section id="learn" className="shm-program">
          <Tabs>
            <TabList>
              <Tab>About</Tab>
              <Tab>Overview</Tab>
              <Tab>FAQ's</Tab>
              <Tab>Testimonials</Tab>
            </TabList>
            <TabPanel>
                <p>Self Healing Mastery is a unique 6 week Mindfulness and energy healing meditation program. The extra added boost of energy healing is for people that wish to take their Mindfulness practice to the next level.</p>
                <p>The Self Healing Mastery program will easily inspire you to further your meditation practice and includes 10 meditations and 6 classes.</p>
                <h2>What will I learn</h2>
                <p>During this 6 week program you will receive a weekly masterclass and also listen to specific topic related meditations.</p>
                <p>The beauty of this program is that it has such a high impact and is so easy to practice.</p>
                <p>Each week you will feel more vibrant as you listen to insights and inspiration throughout your HI CHI Self Healing journey. Begin with your lesson of the week and then simply continue listening to your weekly meditation as and when you can.</p>
                <p>You will experience your energy increase the more you practice. The meditations are often described “as like being on a blissful vacation, each time you listen.”</p>
                <h2>You will receive:</h2>
               
                <ul>
                    <li>4 hours of inspirational energy healing classes</li>
                    <li>10 different blissful meditations that total 3.4 hours</li>
                    <li>Increase your energy and wellbeing forever – it is yours for life.</li>
                    <li>Simply listen and feel your inner bliss rise again.</li>
                </ul>

                <h2>The 6 modules</h2>
                
                  <Accordion>
                      <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              ​Module 1 – A Higher space
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <ul>
                              <li>The principles of HI CHI Self Healing</li>
                              <li>Remember the secrets are all hidden in the basics!</li>
                              <li>Becoming a HI CHI Magic Mirror</li>
                              <li>Scale of Life Force Energy – your scale of where you are right now and where you want to be</li>
                              <li>Shifting your life into a higher space</li>
                              <li>Where is your lazer pointing?</li>
                              <li>Rerouting your focus</li>
                              <li>The essence of HI CHI</li>
                              <li>Bringing your inner child out to play</li>
                              <li>The HI CHI Mirror of Clarity</li>
                              <li>How to set powerful crystal clear intentions and align with them</li>
                              <li>Healing naturally with energy and mindfulness – how big are the possibilities?</li>
                              <li>Getting you back to what you love doing – a life free of pain</li>
                            </ul>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                                ​Module 2 – HI CHI Mindfulness
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                          <ul>
                            <li>HI CHI Mindfulness</li>
                            <li>Learning to quiet the mind</li>
                            <li>The role of the mind</li>
                            <li>Understanding the difference between Yin and Yang energy</li>
                            <li>Softening into Yin</li>
                            <li>Activating your senses</li>
                            <li>Mindfulness healing meditations</li>
                            <li>Creating stillness in your mind, body and spirit first so that healing can occur</li>
                            <li>Learn how to combine HI CHI Mindfulness and cutting edge HI CHI Energy Healing to overcome your pain.</li>
                            <li>Cultivate a daily HI CHI Mindfulness practice that will energize your life.</li>
                            <li>Create a strong core energetic foundation.</li>
                          </ul>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              ​Module 3 – HI CHI Mindfulness with the extra vital ingredient
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                          <ul>
                            <li>Mindfulness amplified</li>
                            <li>Gratitude as alchemy</li>
                            <li>How to access the miraculous power of gratitude</li>
                            <li>Letting go of resistance</li>
                            <li>Shifting your awareness into a healing state</li>
                            <li>Your personal healing insights</li>
                          </ul>
                          </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              ​Module 4 – Your HI CHI Healing breaths
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                          <ul>
                            <li>The breath of life</li>
                            <li>Learning to breathe again with the magic HI CHI healing breaths</li>
                            <li>Bringing HI CHI Life force back into your cells</li>
                            <li>Stimulating the immune response through the breath</li>
                            <li>Your energy transformation through the HI CHI breaths</li>
                            <li>Unraveling – combining your Mindfulness practice with the HI CHI breaths</li>
                            <li>Your power morning HI CHI meditation</li>
                          </ul>
                          </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              ​Module 5 – Your magic HI CHI Mirror Effect
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                          <ul>
                            <li>Loving what is... first</li>
                            <li>The miracle of Love</li>
                            <li>What happens in the body when we are in a state of Love</li>
                            <li>Activating the heart chakra</li>
                            <li>Your mind, body and spirit flowing with love</li>
                          </ul>
                          </AccordionItemPanel>
                      </AccordionItem>


                      <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              ​​Module 6 – Your HI CHI daily power practice
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                          <ul>
                            <li>Grounding using HI CHI Mindfulness</li>
                            <li>Being fully in your body</li>
                            <li>Putting it all together</li>
                            <li>Establishing your HI CHI daily power self healing meditation practice</li>
                            <li>How to use your HI CHI Self Healing Mastery Menu</li>
                            <li>Keeping track of your progress</li>
                          </ul>
                          </AccordionItemPanel>
                      </AccordionItem>

                      
                  </Accordion>
                  
            </TabPanel>

            <TabPanel>
              <h2>Program Overview</h2>
              <ul>
                <li>A 6 week audio program filled with classes and meditations that you can continue to energise and rejuvenate your life with forever – have access to at any time.</li>
                <li>An easy to learn program with 4 high energy hours of experience, insights and valuable classes that are spread out over your 6 week journey.</li>
                <li>3.4 hours of 10 beautiful HI CHI meditations of varying lengths to suit all your time preferences.</li>
                <li>The ability to help yourself shift physical pain, still your mind and create a strong energetic core flow in your life.</li>
                <li>Specific energy healing techniques and mindfulness meditations to give you HI CHI flowing through your body.</li>
                <li>The ability to listen to these recordings for the rest of your life and hone your skills daily.</li>
              </ul>
              </TabPanel>

            <TabPanel>
            <h2>FAQ's</h2>
            <h3>Who created HI CHI Self Healing Mastery</h3>
            <p>The HI CHI Integrated Health Institute was founded by Karina Grant after more than a decade of working as a highly results driven energy healing practitioner, international healing instructor and Mindfulness teacher.</p>
            <p>After being frequently asked “What can I do at home as a regular practice to maintain a balanced energy field?”, Karina created the HI CHI Self Healing Mastery program. Very often people just want to sit back, listen to an audio and let go into a state of healing. This is the purpose and intention of HI CHI Self Healing Mastery.</p>
            <p>You can read Karina’s full <a href="https://hichi-life.com/about/">bio</a> here.</p>
            <p>The HI CHI Programs are the culmination of all her own insights and techniques in healing.</p>
            <h3>How will I benefit from this program?</h3>
            <p>HI CHI Self Healing Mastery literally only requires that you listen to the audio. Once you are listening to the meditations you will find yourself letting go into a high energy state of bliss.</p>
            <p>It is like being on holiday/vacation each time you listen.</p>
            <p>You will continue to feel the benefits of this rested state as endorphins fill your mind and body. It is the power of these good feeling chemical endorphins that boosts your immune system whilst creating a healing space in the mind and body.</p>
            <p>You can expect to feel much more energy, vibrance and inner bliss.</p>
            <h3>Why is this program unique?</h3>
            <p>Karina has created Mindfulness and energy healing programs that are so easy and so playful.</p>
            <p>She focuses on playful energy because children are as close to source energy as it gets. The younger they are the more access they have to their imagination, right brain and creative selves. To experience phenomenal healing results we require the ability to be as playful and childlike as possible.</p>
            <p>Learning through play is how children learn and we all know that a child is able to learn a new language much more quickly than an adult. This is why the HI CHI Integrated Health Institute have made HI CHI so easy to learn how to heal, during your HI CHI meditation practice, in a playful way.</p>
            <p>This is the HI CHI energy that you were born with and what you will reconnect with here. In HI CHI we learn with our inner <strong><em>Chi</em></strong>ld.</p>
            </TabPanel>

            <TabPanel>
								<h2>Testimonials</h2>
								<p>Oh Wow! thank you Karina for tonight’s live Self Mastery coaching call. It has really helped to cement my learning. I loved the questions and detailed answers in particular as some of my questions which I didn’t have time to send in were answered. I am so glad that you emphasized the point of taking our time to learn at our own pace – I really needed to hear that!</p>
                <p>I am sitting here in silent awe with a big smile on my face because I have just realised that my left eye which has been really sore and gritty and sensitive to light all week because of an allergic reaction suddenly seems to be better! I am looking around and blinking without pain for the first time all week, and this is following the HI CHI with Mindful Gratitude Meditation which I listened to immediately following tonight’s class – Amazing!! Xxx</p>
                <p>Patricia Nems</p>
                <hr />
                <p>I did the Module 2 – HI CHI Chillout Zen Den this morning on the beach! Such an amazing experience! It is quite a cloudy day, but somehow, the sun started peeking through the clouds shining directly on me!</p>
                <p>HI CHI has helped me so much. At this stage of my life and in particular with my business, I needed to learn how to relax and allow the universe to bring work to me, without me chasing! The mindfulness module and in particular the meditation have shown me how to do this. I am preferring to concentrate on joy and contentment, as opposed to chasing work! The work now comes to me, during this period I have taken on more work than ever in such a short period.</p>
                <p>I always used to wonder how other people can attract work to them, whilst they seem to expand very little effort. Now I can see how to do this! Relax and allow, in full knowledge, acceptance and expectation that the universe and LOA will bring to you.</p>
                <p>Self Healing Mastery is an amazing course, and I am proud and excited to be part of this! Look forward to meeting everyone online tonight to start module three!</p>
                <p>Ross</p>
                <hr />
                <p>Enjoying the module 2, the party continues.</p>
                <p>It is curious to be able to give a name to the state of well-being and inner joy that I recognize to have lived on other occasions. And to know that it is from this state from which others can be helped, even if it is the way that corresponds to them.</p>
                <p>It is great to know that this special state has a precise and effective technique to activate and increase it.</p>
                <p>It is always good to give and receive a little help in the vast process of evolution.</p>
                <p>From now on, for me that wonderful inner state is called HI CHI.</p>
                <p>With gratitude to all of you,</p>
                <p>Sigfird</p>
                <hr />
                <p>HI CHI has answered my prayers! – Jax, UK</p>
                <hr />
                <p>Hi Karina,</p>
                <p>Wow, wow, WOW!!</p>
                <p>Just listened to the coaching call recording!! Thank you, thank you, thank you!!! I feel soooo full of gratitude for such an in depth response to my question that’s going to help me enormously! I was able to be with you when you were saying about asking what is good about this, I know I can do that, but I was absolutely buzzing when you said if I cant find anything good about this now I have massive determination to turn this into an opportunity to increase my faith! I absolutely get that, and I think maybe because I have spent years hunting and finding gifts retrospectively, it feels like I know almost where to look now, hence the buzz!! Hope that makes sense?</p>
                <p>Much love, light…..and gratitude</p>
                <p>Lesley x x</p>
                <hr />
                <p>I am actually blown away by the fact that from the first time of completing this meditation I am able to move my right arm across my body to beyond my left shoulder which I haven’t been able to do for the last 4 years due to an impingement from rotary cuff tendonopathy.&nbsp; Now, I’m constantly moving my arm across just to check its not my imagination!</p>
                <p>Thank you Karina so much for bringing HI CHI into our lives. I am loving it!</p>
                <p>Lots of love,</p>
                <p>Patricia xxx</p>
                <hr />
                <p>I have been part of the course and the call this week and I have been doing my meditations religiously every day first thing in the morning and loving having structure in that.</p>
                <p>Each module has brought insights as per everyone else.</p>
                <p>I especially love the story of your second birth which made me realise exactly what you mean about aligning heart and head.</p>
                <p>As you know I have been desperately trying to settle in Bath and one way or another houses are slipping away or just not working out and it had started to get frustrating as I’ve been so ‘on it’ and couldn’t understand why nothing was sticking.&nbsp; I lost what I thought was the ideal home back in January through what I consider at the time utter stupidity on my behalf and was so devastated by the loss that finally it made me hone in and really think where exactly do I want to be, what street, what house, what surroundings rather than the airy fairy oh anywhere in this location sort of thing and as soon as I did it bang the house came up and I got it.&nbsp; Heart and head (and bank account) in sync. Of course there are ongoing problems but nothing is unsolvable.</p>
                <p>Emma Cope</p>
                <p>I felt an amazing awareness and deep peace in a noisy and confusing world. Karina, you have innate energy and are unique!</p>
                <p>R.W, London</p>
                <hr />
                <p>The meditation with the “thank you key” opening the gift box was extremely powerful and I felt a tremendous release of the resistance and “hate” I felt towards that challenge. Suddenly my body just relaxed and there was empty free space where the resistance was stuck. I filled it with gratitude and the serenity, pace and calm that filled me was amazing!</p>
                <p>I also really enjoyed the Mindful Breathing and being Mindful of the body. I appreciate the Mindful exercises. I then take it with me during my day and week. The more I practice, the more I am able to melt into Mindfulness easily.</p>
                <p>Halpern, Teacher</p>
                <p>London</p>
                <hr />
                <p>The quality of your voice is beautiful. It is so relaxing, clear and pleasant to listen to. Each and every meditation is uniquely beautiful, with its own flavour and teaching embedded seamlessly.</p>
                <p><b>The meditations are so rich and so masterfully created that they create deep change without any conscious input.</b></p>
                <p>Many, many thanks for all that you have made possible in our community.</p>
                <p>All my love and gratitude – you are amazing!</p>
                <p>C.Wek, Energy therapist</p>
                <p>London</p>
                <hr />
                <p>A saw a beautiful giftbox and within the ribbon I saw the specific issue and it looked nice, serene, flowing and surrendered instead of stuck, fighting and anxious. I felt so much more in my body afterwards – thank you!!</p>
                <p>Levinson</p>
                <p>London</p>
                <hr />
                <p>I am so grateful for the meditations. It has such an amazing and deep effect on me. I find it gets me to a deep place within and interestingly when I listen to it before I fall asleep I have releasing emotional dreams.</p>
                <p>Thank you so much and be blessed.</p>
                <p>H Lewin, Mother</p>
                <p>London</p>
                <hr />
                <p>Thank you so much for the amazing meditation experiences. It is a time for me to get into myself, relax into myself and calm my thoughts. This time gives me insights in all aspects of my life.</p>
                <p>Thank you!!</p>
                <p>R.S</p>
                <p>London</p>
                <hr />
                <p>This program has taught me a major metamorphosis – viewing simple stuff with childlike freshness – and this is majorly powerful!</p>
                <p>I try to implement it throughout the day and it is life transforming. It makes the simple and mundane so refreshing and exciting.</p>
                <p>Thank you so much for being part of my life.</p>
                <p>Hammer</p>
                <p>London</p>
                <hr />
                <p>Karina, your voice in the meditation is sooo pleasant, it really puts one in a very comfortable and relaxed mode. We have a lot of appreciation for having the possibility to be connected to a wonderful “collective” of healing, in our centre.</p>
                <p>Thank you so much for giving us this very special opportunity.</p>
                <p>Daniger</p>
                <p>London</p>
            </TabPanel>
            <StyledRegisterNowButton 
            className="buy-now-button"
            onClick={() => {
                checkoutSingleItem({sku: 'price_1JwQVHIfnzsCIRSOBQufvAZ2'})
              }
            }>Buy now for £127</StyledRegisterNowButton>
          </Tabs>
        </Section>
        </>
    )
}

export default ShmProgramSales