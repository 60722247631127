import React from "react"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import ShmProgram from "../../components/shmProgram"
import ShmProgramSales from "../../components/shmProgramSales"

const SelfHealingMastery = ({ data, location }) => {
    const identity = useIdentityContext()
    return (
      <>
      <Layout location={location} isHomePage>
      <SEO 
          title="Self Healing Mastery - Still your mind and awaken your vitality" 
          description="A 6 week mindfulness &amp; energy healing meditation program that teaches you highly effective mindfulness meditation &amp; self healing techniques to balance your mind &amp; body."
          // image={image.metaImage}
          url="/self-healing-mastery/"
        />
      {identity.user && (identity.user.user_metadata?.roles?.includes('shm') || identity.user.app_metadata?.roles?.includes('shm')) ?
          <ShmProgram />
        : <ShmProgramSales />
          }
     </Layout>
      </>
    )
}

export default SelfHealingMastery